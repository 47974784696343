//代理商接口
import { axios } from '@/utils/request'

//参数名分页查询
export function queryParamsNamePageList(parameter) {
	return axios({
		url: '/goodsCategoryParams/page',
		method: 'post',
		params: parameter.page,
		data: parameter.queryParam
	})
}

//新增参数名
export function addParamsName(parameter){
	return axios({
		url: "/goodsCategoryParams/add",
		method: "post",
		data: parameter
	})
}

//编辑参数名
export function editParamsName(parameter){
	return axios({
		url: "/goodsCategoryParams/edit",
		method: "post",
		data: parameter
	})
}

//获取参数名详情
export function getParamsNameDetail(parameter){
	return axios({
		url: "/goodsCategoryParams/detail",
		method: "post",
		params: parameter
	})
}

//修改参数名状态
export function changeParamsNameStatus(parameter){
	return axios({
		url: "/goodsCategoryParams/delete",
		method: "post",
		data: parameter
	})
}

//参数名集合
export function queryAllParamsNameList(paramter){
	return axios({
		url: "/goodsCategoryParams/list",
		method: "post",
		params: paramter
	})
}

//复制参数名
export function copyParamsName(paramter){
	return axios({
		url: "/goodsCategoryParams/copy",
		method: 'post',
		data:paramter
	})
}